import { Injectable } from '@angular/core';
import { distinctUntilKeyChanged, lastValueFrom, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Company, Establishment, ProvisionOfPremises } from '@dougs/company/dto';
import { Attachment } from '@dougs/core/files';
import { JobService } from '@dougs/core/job';
import { Job } from '@dougs/core/job-dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { mergeObjects, toPromise } from '@dougs/core/utils';
import { CompanyHttpService } from '../http/company.http';
import { FileHttpService } from '../http/file.http';
import { SearchCompanyDataHttpService } from '../http/search-company-data.http';
import { AttachmentService } from '../services/attachment.service';

interface CompanyState {
  activeCompany: Company;
  companies: Company[];
}

@Injectable({
  providedIn: 'root',
})
export class CompanyStateService extends StateService<CompanyState> {
  activeCompany$: Observable<Company> = this.select((state) => state.activeCompany);
  activeCompanyIdChanged$: Observable<Company> = this.activeCompany$.pipe(
    filter((activeCompany) => !!activeCompany),
    distinctUntilKeyChanged('id'),
  );

  hasCompanyCreationOnboarding$: Observable<boolean> = this.select(
    ({ activeCompany }) => activeCompany && activeCompany.accountingFirmId !== 5 && !activeCompany.isCreated,
  );

  canRemoteTransmissionVat$: Observable<boolean> = this.select(
    (state) =>
      ['standardQuaterly', 'standardMonthly'].includes(state.activeCompany.vatConfiguration.type) &&
      (state.activeCompany.flags.includes('canRemoteTransmissionVAT') ||
        !!state.activeCompany.subscription?.isRemoteTransmissionIncluded),
  );

  canRemoteTransmissionIS$: Observable<boolean> = this.select(
    (state) =>
      state.activeCompany.taxRegime === 'is' &&
      (state.activeCompany.flags.includes('canRemoteTransmissionIS') ||
        !!state.activeCompany.subscription?.isRemoteTransmissionIncluded),
  );

  hasPaymentBicInfo$: Observable<boolean> = this.select(
    (state) => !!(state.activeCompany.metadata.paymentMethods && state.activeCompany.metadata?.paymentMethods[0].bic),
  );
  hasPaymentIbanInfo$: Observable<boolean> = this.select(
    (state) => !!(state.activeCompany.metadata.paymentMethods && state.activeCompany.metadata?.paymentMethods[0].iban),
  );

  companies$: Observable<Company[]> = this.select((state) => state.companies);

  constructor(
    private readonly logger: LoggerService,
    private readonly companyHttpService: CompanyHttpService,
    private readonly jobService: JobService,
    private readonly fileHttpService: FileHttpService,
    private readonly attachmentService: AttachmentService,
    private readonly searchCompanyDataHttpService: SearchCompanyDataHttpService,
  ) {
    super();
  }

  get activeCompany(): Company {
    return this.state && this.state.activeCompany;
  }

  get companies(): Company[] {
    return this.state?.companies || [];
  }

  async refreshCompany(companyId?: number): Promise<void> {
    try {
      const companyIdFound: number = companyId || this.state?.activeCompany.id;
      if (companyIdFound) {
        const company: Company = await lastValueFrom(this.companyHttpService.getCompany(companyIdFound));
        this.setCompanyState(company);
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCompanyWithSession(companyId?: number): Promise<void> {
    try {
      const company: Company = await lastValueFrom(
        this.companyHttpService.getCompanyAndRefreshSession(companyId || this.state.activeCompany.id),
      );
      this.setCompanyState(company);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getCompanyById(companyId: number): Promise<Company | null> {
    try {
      return await toPromise(this.companyHttpService.getCompany(companyId));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async updateCompany(company: Company): Promise<Company | null> {
    try {
      const updatedCompany: Company = await lastValueFrom(this.companyHttpService.updateCompany(company));
      const mergedCompany: Company = mergeObjects(this.activeCompany, updatedCompany);
      this.setCompanyState(mergedCompany);
      return mergedCompany;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  setCompanyState(company: Company): void {
    this.setState({
      activeCompany: company,
    });
  }

  async updateResiliationReason(company: Company, resiliationReason: string): Promise<void> {
    try {
      this.setCompanyState({
        ...this.state.activeCompany,
        ...(await lastValueFrom(this.companyHttpService.updateResiliationReason(company.id, resiliationReason))),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateFreeMonthCount(company: Company, freeMonthCount: number): Promise<void> {
    try {
      this.setCompanyState({
        ...this.state.activeCompany,
        subscription: {
          ...this.state.activeCompany.subscription,
          ...(await lastValueFrom(this.companyHttpService.updateFreeMonthCount(company.id, freeMonthCount))),
        },
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  updateSubscriptionCustomAmount(customAmount: number | null): void {
    const company: Company = { ...this.state.activeCompany };
    if (company.subscription) {
      this.setCompanyState({ ...company, subscription: { ...company.subscription, customAmount } });
    }
  }

  async refreshCompanyDataFromSiren(companyId: number, siren?: string): Promise<Company | undefined> {
    try {
      return await lastValueFrom(this.companyHttpService.refreshCompanyFromSiren(companyId, siren));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshEstablishmentFromSiren(companyId: number): Promise<Establishment[] | undefined> {
    try {
      return await lastValueFrom(this.companyHttpService.refreshCompanyEstablishmentFromSiren(companyId));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async resendCompanyStatusProject(
    companyId: number,
    attachmentIds: number[],
    message: string,
    subject: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.companyHttpService.resendCompanyStatusProject(companyId, attachmentIds, message, subject),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async buildAccountingLines(): Promise<Job | null> {
    try {
      return await lastValueFrom(
        this.jobService.handleJob(
          await lastValueFrom(this.companyHttpService.buildAccountingLines(this.state.activeCompany.id)),
        ),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async removeFlag(flag: string): Promise<void> {
    try {
      if (this.state.activeCompany.flags.includes(flag)) {
        this.setCompanyState({
          ...(await lastValueFrom(this.companyHttpService.removeFlag(this.state.activeCompany.id, flag))),
          subscription: this.state.activeCompany.subscription,
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async addFlag(flag: string): Promise<void> {
    try {
      if (!this.state.activeCompany.flags.includes(flag)) {
        this.setCompanyState({
          ...(await lastValueFrom(this.companyHttpService.addFlag(this.state.activeCompany.id, flag))),
          subscription: this.state.activeCompany.subscription,
        });
      }
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachments(company: Company, files: FileList, modelCompanyKey: string, type: string): Promise<void> {
    try {
      const attachments: Attachment[] = await Promise.all(
        Array.from(files).map((file: File) => lastValueFrom(this.fileHttpService.uploadFile(company, type, file))),
      );

      const updatedCompany: Company = this.attachmentService.addMultipleAttachmentFromKey(
        company,
        attachments,
        modelCompanyKey as keyof Company,
      );

      this.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async uploadAttachment(company: Company, file: File, modelCompanyKey: string, type: string): Promise<void> {
    try {
      const attachment: Attachment = await lastValueFrom(this.fileHttpService.uploadFile(company, type, file));

      const updatedCompany: Company = this.attachmentService.addSingleAttachmentFromKey(
        company,
        attachment,
        modelCompanyKey as keyof Company,
      );

      this.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async deleteAttachments(
    company: Company,
    attachment: Attachment,
    modelCompanyKey: string,
    allowMultiple: boolean,
  ): Promise<void> {
    try {
      await lastValueFrom(this.fileHttpService.deleteFile(company, attachment));

      const updatedCompany: Company = allowMultiple
        ? this.attachmentService.removeMultipleAttachmentFromKey(company, attachment, modelCompanyKey as keyof Company)
        : this.attachmentService.removeSingleAttachmentFromKey(company, modelCompanyKey as keyof Company);

      this.setCompanyState(updatedCompany);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async searchCompanyDataFromSiren(siren: string): Promise<Company | null> {
    try {
      return await lastValueFrom(this.searchCompanyDataHttpService.searchCompanyDataFromSiren(siren));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async searchCompanyData(searchText: string): Promise<Company[]> {
    try {
      return await lastValueFrom(this.searchCompanyDataHttpService.searchCompanyData(searchText));
    } catch (e) {
      this.logger.error(e);
      return [];
    }
  }

  async createProvisionOfPremisesTask(taskData: ProvisionOfPremises): Promise<ProvisionOfPremises | null> {
    try {
      return await lastValueFrom(
        this.companyHttpService.createProvisionOfPremisesTask(this.activeCompany.id, taskData),
      );
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async createTaxAppointmentTask(taskData: { message: string }): Promise<void> {
    try {
      return await lastValueFrom(this.companyHttpService.createTaxAppointmentTask(this.activeCompany.id, taskData));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async automateActivationTask(): Promise<boolean> {
    try {
      await lastValueFrom(this.companyHttpService.automateActivationTask(this.activeCompany.id));
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }

  async refreshCompanies(companyId: number): Promise<void> {
    try {
      this.setState({
        companies: this.getAndSetCacheState('companies', companyId)
          ? this.state.companies
          : await toPromise(this.companyHttpService.getCompanies(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
